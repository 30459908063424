<template>
    <section class="online-business">
        <h4>
            <div>Seu negócio <em>online</em></div>
            <div>em <span>3 passos</span></div>
        </h4>
        <div class="online-business-steps">
            <div v-for="( step, index ) in steps" :key="index"
            class="online-business-step">
                <span class="online-business-step-number">{{ step.number }}</span>
                <p v-html="step.text"></p>
            </div>
        </div>
    </section>
</template>
<script setup>
const steps = [
    { number: 1, text: `Insira seus dados de contato` },
    { number: 2, text: `Selecione o tipo de pessoa (<strong>PF</strong> ou <strong>PJ</strong>)`},
    { number: 3, text: `Insira as informações do seu <strong>espaço</strong>`}
]
</script>
<style scoped>
.online-business {
    margin-top: 76px;
}

.online-business h4 {
    margin: 0 auto;
    text-align: center;
    font-size: max(25px, min(5vw, 38px));
    font-weight: bold;
    color: #5E5E5E;
}

.online-business div:first-child {
    font-weight: 600;
}

.online-business div:last-child {
    font-weight: 700;
}


.online-business h4 > div > span {
    text-align: center;
    position: relative;
}


.online-business h4 > div > span::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 94%;
    height: 3px; /* adjust the border width as needed */
    background-color: var(--flip-color-primary);
    transform-origin: 0 100%;
    transform: rotate(-2deg); /* adjust the rotation angle as needed */
  }

.online-business .online-business-steps {
    display: flex;
    flex-wrap: wrap;
    margin-top: 29px;
    justify-content: center;
}

.online-business .online-business-step {
    margin: 12px 0px;
}

.online-business .online-business-step-number {
    background-color: var(--flip-color-primary);
    border-radius: 50%;
    width: 62px;
    height: 62px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    display: flex;
    color: #fff;
    font-weight: 900;
    font-size: max(32px, min(5vw, 40px));
}

.online-business .online-business-step p {
    max-width: 52%;
    text-align: center;
    margin: 12px auto 0 auto;
    font-size: max(15px, min(2vw, 16px));
    font-weight: 500;
    color: #5E5E5E;
}

@media (min-width: 599.99px) {
    .online-business {
        margin-top: 116px;
    }

    .online-business .online-business-step p {
        max-width: 52%;
        text-align: center;
        margin: 12px auto 0 auto;
    }
}

@media (min-width: 1180px) {
    .online-business h4 {
        text-align: left;
        margin: 0px;
        margin-right: 50px !important;
        white-space: nowrap;
    }

    .online-business .online-business-step p {
        width: 250px;
        margin: 12px auto 0 auto;
        max-width: 67%;
    }

    .online-business {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .online-business .online-business-steps {
        flex-wrap: nowrap;
    }
}
</style>