import {
    baseUrlMissions,
} from "@/shared/http/api";

export const landingPageGetMissions = () => {
    const endpoint = `${baseUrlMissions}/api/v1/missions/public?&vigente=true`;
   
    return fetch(endpoint, {
        method: 'GET'
    })
    .then(res => res.json())
    .then(output => { return output })
    .catch(error => { throw error })
}