<template>
    <div class="blog-lp">
        <signUp />
        <div class="centered">
            <partners :class="isMobile ? 'q-px-sm' : ''" />
            <onlineBusiness :class="isMobile ? 'q-px-sm' : ''" />
            <missions :class="isMobile ? 'q-px-sm' : ''" />
            <divulgation :class="isMobile ? 'q-px-sm' : ''" />
            <talkAboutUs class="gt-sm" :class="isMobile ? 'q-px-sm' : ''" />
            <skills :class="isMobile ? 'q-px-sm' : ''" />
            <solution :class="isMobile ? 'q-px-sm' : ''" />
            <info :class="isMobile ? 'q-px-sm' : ''" />
        </div>
        <plans />
        <div class="centered">
            <faq :class="isMobile ? 'q-px-sm' : ''" />
        </div>
    </div>
</template>
<script setup>
import signUp from '@/blog-lp/sections/signUp.vue';
import partners from '@/blog-lp/sections/partners.vue';
import missions from '@/blog-lp/sections/missions.vue';
import onlineBusiness from '@/blog-lp/sections/business.vue';
import divulgation from '@/blog-lp/sections/divulgation.vue';
import talkAboutUs from '@/blog-lp/sections/talkAboutUs.vue';
import skills from '@/blog-lp/sections/skills.vue';
import solution from '@/blog-lp/sections/solution.vue';
import info from './sections/info.vue';
import plans from './sections/plans.vue';
import faq from './sections/faq.vue';

import { useQuasar } from 'quasar';
import { computed, onMounted } from 'vue';

const $q = useQuasar();

const isMobile = computed(() => {
    return $q.screen.lt.sm
})

const sendHeight = () => {
    setTimeout(() => {
        const height = document.documentElement.scrollHeight;

        window.parent.postMessage({
            type: 'signUpUpdateHeight',
            height: height 
        }, 
        'https://flip.net.br');
    }, 80)
}

window.addEventListener('resize', () => sendHeight())

// Monitora mudanças no DOM, por exemplo, quando um componente collapse é aberto
const observer = new MutationObserver(sendHeight);
observer.observe(document.body, { attributes: true, childList: true, subtree: true });

const scrollToForm = () => {
    window.parent.postMessage('scrollToTop', 'https://flip.net.br');
}

onMounted(() => {
    sendHeight();
    
    const buttonsGoToForm = Array.from(document.querySelectorAll('.button-go-to-form'));

    buttonsGoToForm.forEach(btn => {
        btn.addEventListener('click', () => scrollToForm())
    })
})
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
}

body {
    overflow: hidden;
}

.max-width {
    max-width: 1167px;
}

.blog-lp a {
    text-decoration: none;
}

.blog-lp .centered {
    max-width: 1167px;
    padding: 0px 10px;
    margin: 0 auto
}

li {
    list-style: none;
}

ul {
    margin: 0px;
    padding: 0px;
}

.blog-lp {
    font-family: "Montserrat", sans-serif !important;
}

.blog-lp .anchor-link-external {
    color: #5F6368;
}

.blog-lp .button-scale-animation {
    -webkit-transition: all 0.4s cubic-bezier(.5, .24, 0, 1);
    transition: all 0.4s cubic-bezier(.5, .24, 0, 1);
}

.blog-lp .button-scale-animation:hover {
    transform: translateY(-0.25em);
    box-shadow: 0 0 0.5em 0em var(--flip-color-primary);
    border-color: var(--flip-color-primary);
}

.blog-lp .swiper-pagination {
   bottom: var(--swiper-pagination-bottom, 1px) !important;
}

.blog-lp .swiper-button-next, .swiper-button-prev {
    color: var(--flip-color-primary) !important;
}

.blog-lp .swiper-pagination-bullet-active {
    background: var(--flip-color-primary);
}

.brands>h4,
.online-business>h4,
.divulgation>h4 {
    color: #5E5E5E;
    text-align: center;
    max-width: 80%;
}


.window-width {
    width: fit-content !important;
}

.auth {
    background: transparent !important;
    min-height: 100% !important;
}

.sign-up .center .auth p.text-justify {
    color: #5F6368;
}

.form {
    height: fit-content !important;
}
</style>