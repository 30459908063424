<template>
    <section class="skills">
        <div class="skills-content">
            <div class="skills-img">
                <img src="https://flip.net.br/wp-content/uploads/2024/06/skills.png" loading="lazy"
                    alt="Domine as habilidades mais requisitadas do mercado com os cursos da FlipNet!" />
            </div>
            <div class="skills-inner">
                <h6>Domine as habilidades mais requisitadas do mercado com os cursos do Flipnet!</h6>
                <p>Tenha acesso a diversos treinamentos rápidos e práticos feitos para impulsionar seu negócio e te ajudar a alcançar resultados extraordinários.</p>
                <a class="button-go-to-form button-scale-animation">Começar agora</a>
            </div>
        </div>
    </section>
</template>
<style scoped>
.skills {
    margin-top: 68px;
}

.skills-img {
    max-width: 100vw;
    display: flex;
    justify-content: center;
}

.skills-img img {
    width: 100%;
    max-width: 721px;
}

.skills-inner {
    max-width: 721px;
    margin: 0 auto;
}

.skills-inner h6 {
    font-size: max(25px, min(3vw, 30px));
    margin: 28px 0px 18px 0px;
    line-height: 1.4;
}

.skills-inner p {
    font-size: max(15px, min(5vw, 17px));
}

.skills-inner h6,
.skills-inner p {
    text-align: center;
}

.skills-inner a {
    height: 60px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    padding-left: 0px;
    max-width: 391px;
    margin: 20px auto 0px auto;
    font-size: max(12px, min(5vw, 17px));
    text-transform: uppercase;
    color: #fff;
    background-color: var(--flip-color-primary);
}

@media (min-width: 1180px) {
    .skills {
        margin-top: 116px;
    }
    
    .skills-content {
        display: flex;
        justify-content: space-between;
    }

    .skills-img img {
        width: 721px;
        height: 328px;
    }

    .skills-inner {
        margin-left: 45px !important;
    }

    .skills-inner h6 {
        margin-top: 0px;
    }

    .skills-inner a {
        width: 202px;
        border-radius: 20px;
        float: left;
        height: 40px;
    }

    .skills-inner * {
        text-align: left !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
</style>